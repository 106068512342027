// + de paramètres possible sur https://swiperjs.com/demos et sur https://swiperjs.com/swiper-api
var swiper = new Swiper(".widgetActualite-swiper", {
    spaceBetween: 20,
    slidesPerView: 1,
    pagination: {
        el: ".widgetActualite-pagination",
        bulletClass: 'swiper-pagination-bullet widgetActualite-bullet',
        bulletActiveClass: 'swiper-pagination-bullet-active widgetActualite-bullet--active',
        clickable: true
    }
});

var swiper2 = new Swiper(".widgetCatalogue-swiper", {
    spaceBetween: 20,
    slidesPerView: 1,
    pagination: {
        el: ".widgetCatalogue-pagination",
        bulletClass: 'swiper-pagination-bullet widgetCatalogue-bullet',
        bulletActiveClass: 'swiper-pagination-bullet-active widgetCatalogue-bullet--active',
        clickable: true
    }
});
